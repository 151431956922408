import { computed } from 'vue'
import { useRoute } from 'vue-router'

export function usePageName() {
  const route = useRoute()
  const pageName = computed(() => {
    return (route.name! as string).split('___')[0]
  })

  return {
    pageName
  }
}