<template>
  <!-- <div>Page navigation from: {{ router.currentRoute.value.name }}</div> -->
  <!-- <div class="navigation-bar">
    <div class="navigation-container container">
      <NuxtLink v-if="previousPageEntry" 
        :to="localePath($rt(previousPageEntry.path).toString())"
      >
      <div class="pr-2"> &lt; </div><div class="title">{{ rt(previousPageEntry['menu-entry']).toString() }}</div>
      </NuxtLink>
      <div v-else></div>
      
      <NuxtLink v-if="nextPageEntry"
        :to="localePath($rt(nextPageEntry.path).toString())"
      >
      <div class="title">{{ rt(nextPageEntry['menu-entry']).toString() }}</div> <div class="pl-2">&gt;</div>
      </NuxtLink>
    </div>
  </div> -->

  <div v-if="previousPageEntry || nextPageEntry" class="container mx-auto pb-16 pt-16 px-4 sm:px-12 laptop:px-24">
    <div class="navigation-container-2">
      <div class="overflow-hidden hidden md:block" style="flex: 1 1 0px;">
        <NuxtLink v-if="previousPageEntry" class="flex flex-col flex-grow items-end text-right"
          :to="localePath($rt(previousPageEntry.path).toString())"
        >
          <div class="flex items-center gap-4 w-full"><div>< {{ $t('miscellaneous.previous') }}</div><hr /></div>
          <div class="flex flex-col items-end w-full p-4"style="max-width: 600px;">
            <div class="title2">{{ rt(previousPageEntry['menu-entry']).toString() }}</div>
            <div class="description2">{{ rt(previousPageEntry['description']).toString() }}</div>
          </div>
        </NuxtLink>
      </div>

      <div class="items-center leading-5 hidden md:flex">&nbsp;<div class="circle" />&nbsp;</div>
      
      <div class="overflow-hidden" style="flex: 1 1 0px;">
        <NuxtLink v-if="nextPageEntry" class="flex flex-col flex-grow"
          :to="localePath($rt(nextPageEntry.path).toString())"
        >
          <div class="flex items-center gap-4"><hr /><div>{{ $t('miscellaneous.next') }} ></div></div>
          <div class="p-4" style="max-width: 600px;">
            <div class="title2">{{ rt(nextPageEntry['menu-entry']).toString() }}</div>
            <div class="description2">{{ rt(nextPageEntry['description']).toString() }}</div>
          </div>
        </NuxtLink>
        <!-- we reached the end of the menu, send user to registration -->
        <NuxtLink v-else class="flex flex-col flex-grow"
          :to="panelLinks.registerUrl.value"
        >
          <div class="flex items-center gap-4"><hr /><div>{{ $t('miscellaneous.next') }} ></div></div>
          <div class="p-4" style="max-width: 600px;">
            <div class="title2">{{ $t('menuExternal.try-it.menu-entry').toString() }}</div>
            <div class="description2">{{ $t('menuExternal.try-it.title').toString() }}</div>
          </div>
        </NuxtLink>
      </div>
    </div>

    <div class="overflow-hidden md:hidden mt-8" style="flex: 1 1 0px;">
        <NuxtLink v-if="previousPageEntry" class="flex flex-col flex-grow"
          :to="localePath($rt(previousPageEntry.path).toString())"
        >
          <div class="flex items-center gap-4 w-full"><div>< {{ $t('miscellaneous.previous') }}</div><hr /></div>
          <div class="flex flex-col w-full p-4"style="max-width: 600px;">
            <div class="title2">{{ rt(previousPageEntry['menu-entry']).toString() }}</div>
            <div class="description2">{{ rt(previousPageEntry['description']).toString() }}</div>
          </div>
        </NuxtLink>
      </div>
  </div>
</template>

<script lang="ts" setup>
import type { MenuEntries } from '~/utils/generic';
import { useRouter } from 'vue-router'; // Import the useRouter function from the vue-router module
const { locale, t, tm, rt, te } = useI18n();
const panelLinks = usePanelLinks();
const localePath = useLocalePath()

// get the current route name
const router = useRouter(); // Create a router object using the useRouter function
//const currentRouteName = router.currentRoute.value.name; // Access the current route name using the router object
const {pageName} = usePageName()


import { computed } from 'vue';


const menuEntries = computed(() => tm('menuInfo') as MenuEntries);

const currentPageEntry = computed(() => menuEntries.value[pageName.value]);

const keys = computed(()=> {
  return  Object.keys(menuEntries.value)
})

const previousPageEntry = computed(() => {
  const currentPageIndex = keys.value.indexOf(pageName.value);
  if (currentPageIndex > 0) {
    const previousPageName = keys.value[currentPageIndex - 1];
    if(rt(menuEntries.value[previousPageName].title).toString()) {
      return menuEntries.value[previousPageName];
    }
  }
  return null;
});

const nextPageEntry = computed(() => {
  const currentPageIndex = keys.value.indexOf(pageName.value);
  if (currentPageIndex > 0 && currentPageIndex < keys.value.length - 1) {
    const nextPageName = keys.value[currentPageIndex + 1];
    return menuEntries.value[nextPageName];
  }
  return null;
});
</script>

<style scoped>
/* .navigation-bar {
  @apply bg-backgroundgray flex justify-center p-4 mt-8;
}
.navigation-container {
  @apply flex justify-between text-xl items-center laptop:px-10; 
}
.navigation-container a {
  @apply hover:text-highlight flex items-center flex-grow;
  max-width: calc(50% - 1rem);
  white-space: nowrap;
}
.navigation-container a:last-child {
  @apply justify-end;
}
.title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 1rem);
}
*/

.navigation-container-2 {
  @apply flex justify-evenly items-start gap-4; 
} 
a {
  @apply hover:text-highlight
}
.navigation-container-2 hr {
  @apply border-darkgray;
}
a:hover hr {
  @apply border-highlight;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  padding: 0;
  flex-grow: 1;
  border-top-width: 1px;
  border-top-style: solid;
}
.circle {
  @apply w-2 h-2 rounded-full bg-darkgray;
}
.title2 {
  @apply inline-block overflow-hidden text-ellipsis md:text-slg lg:text-25px laptop:text-35xl font-bold pb-4;
  max-width: calc(100% - 1rem);
}

</style>